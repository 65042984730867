import { Forum, Notifications, Paid, Person, Receipt } from "@mui/icons-material";
import { Avatar, Badge, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import { useGetNotificationsQuery, useUpdateNotificationReadMutation } from "api/notificationApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useNavigate } from "react-router-dom";
import { socket } from "socket"

const NOTIFICATION_ROUTES = {
    NEW_USER_REGISTER: "/admin/user",
    REJECTED_BY_SERVICE_PROVIDER: '/admin/user',
    ACCOUNT_ACTIVATED: "/merchant/profile",
    ACCOUNT_BLOCKED: "/merchant/profile",
    NEW_CONTACT_US_MESSAGE: "/admin/contact-us-messages",
    TRANSACTION_PAID: '/merchant/transactions/details',
    BALANCE_SETTELED: '/admin/invoice',
}
const NOTIFICATION_ICONS = {
    NEW_USER_REGISTER: <Person />,
    ACCOUNT_ACTIVATED: <Person />,
    REJECTED_BY_SERVICE_PROVIDER: <Person />,
    ACCOUNT_BLOCKED: <Person />,
    NEW_CONTACT_US_MESSAGE: <Forum />,
    TRANSACTION_PAID: <Paid />,
    BALANCE_SETTELED: <Receipt />
}

export default function NotifcationsMenu() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const [page, setPage] = useState(1)
    const { data: notification, isLoading: notificationLoading, isFetching: notificationFetching, isError, refetch } = useGetNotificationsQuery({
        page
    })
    const [updateNotificationRead] = useUpdateNotificationReadMutation()
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    const handleClick = (event) => {
        updateNotificationRead()
        setAnchorEl(event.currentTarget);
        refetch()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getNotificationsHandler = () => {
        setPage(prevpage => prevpage + 1)
    }

    const [sentryRef] = useInfiniteScroll({
        loading: notificationLoading || notificationFetching,
        hasNextPage: notification?.notifications?.hasNextPage,
        onLoadMore: getNotificationsHandler,
        disabled: isError,
        rootMargin: '0px 0px 40px 0px',
    });


    // useEffect(() => {
    //     getNotificationsHandler()
    // }, [])

    const onNotificationClicked = (item) => {
        if (item?.type === "NEW_CONTACT_US_MESSAGE") {
            navigate(`${NOTIFICATION_ROUTES[item?.type]}?messageId=${item?.target}`)
        } else if (!["ACCOUNT_ACTIVATED", "ACCOUNT_BLOCKED"].includes(item?.type)) {
            navigate(`${NOTIFICATION_ROUTES[item?.type]}/${item?.target}`)
        }
        updateNotificationRead({ id: item?.id })
        handleClose()
        refetch()
    }

    useEffect(() => {
        if (!!socket) {
            socket.on("notification", (data) => {
                refetch()
            })
        }
    }, [socket])

    return (
        <>
            <Tooltip title={t("Notifications")}>
                <IconButton
                    size="large"
                    variant="contained"
                    color="inherit"
                    aria-describedby={id}
                    onClick={handleClick}
                >
                    <Badge badgeContent={notification?.unreadCount} color="error">
                        <Notifications />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{ maxHeight: '80%' }}
            >
                <Box px={20} py={15}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>{t("Notifications")}</Typography>
                </Box>
                <Divider />
                <List dense={true} sx={{ py: 0, width: 350 }}>
                    {
                        notification?.notifications?.docs?.map((item, index) => (
                            <ListItem
                                onClick={() => onNotificationClicked(item)}
                                key={item?.id}
                                sx={() => ({
                                    cursor: "pointer",
                                    bgcolor: item.read === 2 ? "#FFFFFF" : "#e9f3fe",
                                    "&:hover": {
                                        bgcolor: "#f0f2f5"
                                    },
                                    py: 10
                                })}
                                divider={index < notification?.notifications?.docs.length - 1}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        {NOTIFICATION_ICONS[item?.type]}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={theme.direction === "rtl" ? item?.body_ar : item?.body_en}
                                    primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
                                    secondary={moment(item.createdAt).fromNow()}
                                    secondaryTypographyProps={{ variant: 'subtitle2', fontWeight: 300, sx: { textAlign: "start" } }}
                                />
                            </ListItem>
                        ))
                    }

                    {(notificationLoading || notificationFetching || notification?.notifications?.hasNextPage)
                        &&
                        <ListItem ref={sentryRef} sx={{ display: "flex", justifyContent: "center", height: 55 }}>
                            <CircularProgress color="primary" size={30} />
                        </ListItem>
                    }
                    {(!notificationLoading && !notification?.notifications?.docs?.length)
                        &&
                        <ListItem sx={{ display: 'flex', justifyContent: "center" }}>
                            <Typography color="primary" >{t("No notifications yet")}</Typography>
                        </ListItem>
                    }
                    {
                        (!(notificationLoading || notificationFetching) && !!notification?.notifications?.docs?.length && !notification?.notifications?.hasNextPage)
                        &&
                        <ListItem sx={{ display: 'flex', justifyContent: "center", py: 10 }}>
                            <Typography color="primary" >{t("No more notifications")}</Typography>
                        </ListItem>
                    }
                </List>
            </Popover>
        </>
    )
}