import { Box, Chip, Container, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";
import { DataTable, MerchantName, PaymentMethodIcon } from "modules/common";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { get, omit, omitBy } from "lodash";
import { useGetTransactionsQuery, useLazyGetTransactionExcelQuery } from "api/transactionApi";
import { FilterList, Search } from "@mui/icons-material";
import TransactionsFilterDialog from "./components/TransactionsFilterDialog";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";

const $COLUMN_WIDTH = 200
export default function AdminTransactions() {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { profile } = useSelector(state => state?.auth);
  const status_colors = { CANCELED: "info", EXPIRED: "default", FAILED: "error", IN_PROGRESS: "warning", PAID: "success" }

  const [openFilterDialog, setOpenFilterDialog] = useState(false)
  const [page, setPage] = useState(1)
  const [params, setParams] = useState({
    page: 1,
    limit: 100,
  })

  const { data: transactions, isLoading: transactionsLoaing, isFetching } = useGetTransactionsQuery(params)

  const pageChangeHandler = ({ page }) => {
    if (!isFetching) {
      setPage(page)
      setParams((prevParams) => ({ ...prevParams, page }))
    }
  }

  const confirmFilterHandler = (payload) => {
    setParams({
      page,
      limit: 100,
      ...omitBy(payload, v => !Boolean(v))
    })
  }

  const [searchKey, setSearchKey] = useState(null)
  const timerRef = useRef(null)

  const onSearchKey = ({ target: { value } }) => {
    setSearchKey(value)
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      if (!!value) {
        setParams(prev => ({ ...prev, searchKey: value }))
      } else {
        setParams(omit(params, ['searchKey']))
      }
    }, 1000);
  }

  const [getTransactionExcel, { isLoading: excelLoading }] = useLazyGetTransactionExcelQuery()
  const [excelError, setExcelError] = useState(null)
  const [excelIsSuccess, setExcelIsSuccess] = useState(false)

  const getTransactionExcelHandler = async () => {
    try {
      if (!!profile?.email) {
        const payload = omit(params, ['page', 'limit'])
        await getTransactionExcel({ email: profile?.email, ...payload })
        setExcelIsSuccess(true)
      } else {
        setExcelError("transactionExcel.emailError")
      }
    } catch (error) {
      setExcelError(error?.message)
    } finally {
      setTimeout(() => {
        setExcelIsSuccess(false)
      }, 3000);
      setTimeout(() => {
        setExcelError(null)
      }, 3000);
    }
  }

  //===================Data tables handlers============================
  const [transaction, setTransaction] = useState(null)
  const [_, setTransactionDialog] = useState(false)

  const openTransactionDialogHandler = (transaction) => {
    setTransaction(transaction)
    setTransactionDialog(true)
  }

  const TransactionStatus = (params) => {
    return (
      <Chip
        label={t(params?.value)}
        sx={{ color: "white", flex: 1, fontWeight: 500 }}
        color={status_colors[params?.value]}
        disabled={params?.value === "PAID"}
        onClick={(event) => {
          event.stopPropagation()
          openTransactionDialogHandler(params?.row)
        }}
      />
    )
  }
  const IDCell = ({ value }) => (
    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
      {value ?? '-'}
    </Typography>
  )
  let columns = [
    { field: "transactionNumber", renderCell: IDCell, sortable: false, headerName: t("Transaction number"), width: $COLUMN_WIDTH * 0.8 },
    { field: "currentStatus", renderCell: TransactionStatus, sortable: false, headerName: t("Status"), width: $COLUMN_WIDTH * 0.7 },
    { field: "name", valueGetter: ({ value }) => value || t("N/A"), sortable: false, headerName: t("Client"), width: $COLUMN_WIDTH * 1.1 },
    { field: "user", renderCell: ({ value }) => <MerchantName creator={{ ...value, parent: profile?.id === value?.id ? null : profile?.id }} business={get(value, 'business')} />, sortable: false, headerName: t("Merchant"), width: $COLUMN_WIDTH * 1.1 },
    { field: "amount", sortable: false, headerName: t("Amount"), width: $COLUMN_WIDTH * 0.5, },
    { field: "source", renderCell: ({ value }) => <PaymentMethodIcon method={value} />, valueGetter: ({ row }) => row?.source?.paymentMethod, headerName: t("Payment Method"), width: $COLUMN_WIDTH * 0.7 },
    { field: "providerInvoiceId", renderCell: IDCell, sortable: false, headerName: t("Provider Invoice ID"), width: $COLUMN_WIDTH * 0.8 },
    { field: "createdFrom", valueGetter: ({ value }) => t(value ?? ''), sortable: false, headerName: t("Transaction Source"), width: $COLUMN_WIDTH * 0.8 },
    { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
  ];


  return (
    <>
      <Helmet>
        <title>{t("Transactions List")} | {$APP_NAME}</title>
      </Helmet>
      <Container>
        <Box display="flex" alignItems="center" my={10}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Transactions")}</Typography>
          <IconButton onClick={() => setOpenFilterDialog(true)} color="primary">
            <FilterList />
          </IconButton>
          <TransactionsFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            onConfirm={confirmFilterHandler}
          />
        </Box>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 10 }}>
          <Grid item xl={4} lg={4} md={6} sm={10} xs={10}>
            <TextField
              fullWidth
              label={t("Search")}
              size="small"
              value={searchKey ?? ''}
              onChange={onSearchKey}
              InputProps={
                theme.direction === "rtl" ?
                  {
                    endAdornment: <InputAdornment position="end">
                      <Search />
                    </InputAdornment>,
                  }
                  :
                  {
                    startAdornment: <InputAdornment position="start">
                      <Search />
                    </InputAdornment>,
                  }
              }
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "end" }}>
            <LoadingButton
              variant="contained"
              loading={excelLoading}
              disabled={!!transaction?.docs?.length}
              sx={{ textTransform: "none" }}
              onClick={() => getTransactionExcelHandler()}
            >{t("Excel")}
            </LoadingButton>
          </Grid>
        </Grid>
        <Box py={5} my={5}>
          {excelIsSuccess && <Typography color="success.main">{t("transactionExcel.success")}</Typography>}
          {!!excelError && <Typography color="error.main">{t(excelError)}</Typography>}
        </Box>
        <Paper sx={{ height: "75vh" }}>
          <DataTable
            rows={transactions?.docs ?? []}
            columns={columns}
            rowCount={transactions?.totalDocs ?? 0}
            onPaginationModelChange={pageChangeHandler}
            paginationModel={{ page: page - 1, pageSize: 100 }}
            loading={transactionsLoaing || isFetching}
            onRowClick={row => navigate(`/merchant/transactions/details/${row?.id}`)}
          />
        </Paper>
      </Container >
    </>
  )
}