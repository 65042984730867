import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import { TRANSACTION_STATUSES } from 'constants';
import { Chip } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';

const failStatuses = [TRANSACTION_STATUSES.FAILED, TRANSACTION_STATUSES.CANCELED, TRANSACTION_STATUSES.EXPIRED];
const pendingStatuses = [TRANSACTION_STATUSES.IN_PROGRESS, TRANSACTION_STATUSES.INITIATED];

export default function InvoiceTagStatus({ status}) {

    const { t } = useTranslation();

    const IconStatus = () => {
        if (includes(failStatuses, status)) { return <Cancel color="white" /> }
        if (status === TRANSACTION_STATUSES.PAID) { return <Check color="white" /> };
    }

    const color = useMemo(_ => {
        if (includes(failStatuses, status)) { return "error" };
        if (status === TRANSACTION_STATUSES.PAID) { return "success" };
        if (includes(pendingStatuses, status)) { return "secondary" }
    }, [status]);

    return (
        <Chip
            label={t(status)}
            icon={<IconStatus />}
            color={color}
            sx={{px: 50, fontSize: 20}}
        />
    )
}