export const CREATE_TRANSACTION = {
    title: "Create Transaction",
    url: "https://backend.ezpayuae.com/api/v1/vendor/transaction",
    method: "POST",
    description: "This API for creating transaction and when you create a transaction, a link is created for you and through it you can settle the transaction.",
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "amount",
                type: [
                    "number"
                ],
                description: "The total amount payable for the order."
            }
        ]
    },
    optionalFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "name",
                type: [
                    "string"
                ],
                description: "The customer's name"
            },
            {
                inputParameter: "phone",
                type: [
                    "string"
                ],
                description: "The customer's phone number"
            },
            {
                inputParameter: "serviceDetails",
                type: [
                    "string"
                ],
                description: "The total amount payable for the order."
            },
        ]
    },
    sampleCodes: [
        {
            label: "Request",
            value: {
                amount: 100,
                name: "Johne Doe",
                phone: "+966581849999",
                serviceDetails: "This transaction for buying Blue Pen."
            }
        },
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    transactionNumber: 1161,
                    invoiceURL: "https://www.ezpay.sa/payment?id=699c0c59278faa001569a339&country=KWT",
                }
            }
        }
    ]
}

export const CANCEL_TRANSACTION = {
    title: "Cancel Transaction",
    url: "https://backend.ezpayuae.com/api/v1/vendor/transaction",
    method: "PUT",
    description: "This API for canceling transaction.",
    sampleCodes: [{
        label: "Response",
        value: {
            transactionNumber: 1161,
            currentStatus: "CANCELED",
            amount: 100,
            name: "Johne Doe",
            phone: "+966581849999",
            serviceDetails: "This transaction for buying Blue Pen.",
            paymentDate: null,
            statusTrack: [
                {
                    dateTime: "2024-07-08T14:40:09.465Z",
                    status: "IN_PROGRESS"
                },
                {
                    dateTime: "2024-07-08T16:27:11.508Z",
                    status: "CANCELED"
                }
            ],
            createdAt: "2024-07-08T15:57:13.102Z"
        }
    },
    {
        label: "Error Response",
        value: {
            error: "INVALID_CANCEL_TRANSACTION",
            status: 633,
            success: false
        }
    }
    ],
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "transactionNumber",
                type: [
                    "string"
                ],
                description: "The transaction id for which you want to know its details"
            },
        ]
    },
}

export const GET_TRANSACTION_BY_ID = {
    title: "Get Transaction",
    url: "https://backend.ezpayuae.com/api/v1/vendor/transaction/:transactionNumber",
    method: "GET",
    description: "This API for get transaction by id.",
    sampleCodes: [
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    transactionNumber: 1161,
                    currentStatus: "CANCELED",
                    name: null,
                    phone: null,
                    serviceDetails: null,
                    paymentDate: null,
                    amount: 360,
                    statusTrack: [
                        {
                            dateTime: "2024-07-08T14:40:09.465Z",
                            status: "IN_PROGRESS"
                        },
                        {
                            dateTime: "2024-07-08T16:27:11.508Z",
                            status: "CANCELED"
                        }
                    ],
                    createdAt: "2024-07-08T15:57:13.102Z"
                }
            }
        },
        {
            label: "Error Response",
            value: {
                error: "TRANSACTION_NOT_FOUND",
                status: 619,
                success: false
            }
        }
    ]
}

export const LIST_TRANSACTIONS = {
    title: "List Transactions",
    url: "https://backend.ezpayuae.com/api/v1/vendor/transaction",
    method: "GET",
    description: "This API for listing transactions.",
    optionalFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "page",
                type: [
                    "number"
                ],
                description: "The page number for pagination, (If it is not sent, the default value is 1)."
            },
            {
                inputParameter: "limit",
                type: [
                    "number"
                ],
                description: "The trasnsactions per page, (If it is not sent, the default value is 20)"
            },
            {
                inputParameter: "searchKey",
                type: [
                    "string"
                ],
                description: "Search for transactions by text using (name - phone - transactionNumber)."
            },
            {
                inputParameter: "fromAmout",
                type: [
                    "number"
                ],
                description: "Get the transactions that are equal to or bigger than this fromAmoutn value."
            },
            {
                inputParameter: "toAmout",
                type: [
                    "number"
                ],
                description: "Get the transactions that are equal to or smaller than this fromAmoutn value."
            },
            {
                inputParameter: "fromDate",
                type: [
                    "ISO string"
                ],
                description: "Get the transactions that created on this date and those that created after it."
            },
            {
                inputParameter: "toDate",
                type: [
                    "ISO string"
                ],
                description: "Get the transactions that created on this date and those that created before it."
            },
            {
                inputParameter: "currentStatus",
                type: [
                    "string"
                ],
                description: "Search for transactions by transaction status.\nThe expected values :\nIN_PROGRESS\nPAID\nFAILED\nEXPIRED\nCANCELED\nREFUNDED"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    docs: [
                        {
                            transactionNumber: 1161,
                            currentStatus: "CANCELED",
                            name: null,
                            phone: null,
                            serviceDetails: null,
                            paymentDate: null,
                            amount: 360,
                            statusTrack: [
                                {
                                    dateTime: "2024-07-08T14:40:09.465Z",
                                    status: "IN_PROGRESS"
                                },
                                {
                                    dateTime: "2024-07-08T16:27:11.508Z",
                                    status: "CANCELED"
                                }
                            ],
                            createdAt: "2024-07-08T15:57:13.102Z"
                        },
                        {
                            transactionNumber: 1114,
                            currentStatus: "IN_PROGRESS",
                            name: null,
                            phone: null,
                            serviceDetails: null,
                            paymentDate: null,
                            amount: 234,
                            statusTrack: [
                                {
                                    dateTime: "2024-05-14T11:05:37.789Z",
                                    status: "IN_PROGRESS"
                                }
                            ],
                            createdAt: "2024-05-14T11:21:36.890Z"
                        },
                    ],
                    totalDocs: 6,
                    limit: 100,
                    totalPages: 1,
                    page: 1,
                    pagingCounter: 1,
                    hasPrevPage: false,
                    hasNextPage: false,
                    prevPage: null,
                    nextPage: null
                }
            }
        },
        {
            label: "Error Response",
            value: {
                error: "TRANSACTION_NOT_FOUND",
                status: 619,
                success: false
            }
        }
    ]
}

export const REFUND_TRANSACTION = {
    title: "Refund Transaction",
    url: "https://backend.ezpayuae.com/api/v1/vendor/transaction/refund",
    method: "PUT",
    description: "This API for refund transaction.",
    sampleCodes: [{
        label: "Response",
        value: {
            transactionNumber: 1161,
            currentStatus: "CANCELED",
            amount: 100,
            name: "Johne Doe",
            phone: "+966581849999",
            serviceDetails: "This transaction for buying Blue Pen.",
            paymentDate: null,
            statusTrack: [
                {
                    dateTime: "2024-07-08T14:40:09.465Z",
                    status: "IN_PROGRESS"
                },
                {
                    dateTime: "2024-07-08T16:27:11.508Z",
                    status: "CANCELED"
                }
            ],
            createdAt: "2024-07-08T15:57:13.102Z"
        }
    },
    {
        label: "Error Response",
        value: {
            error: "INVALID_REFUND",
            status: 648,
            success: false
        }
    }
    ],
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "transactionNumber",
                type: [
                    "string"
                ],
                description: "The transaction id for which you want to refund"
            },
        ]
    },
}

export const WEBHOOK_SAMPLE_CODE = [{
    label: "Respone",
    value: {
        event: 'TRANSACTION_STATUS_CHANGED',
        currentStatus: 'one of these values => ["IN_PROGRESS", "PAID", "FAILED", "EXPIRED", "CANCELED", "REFUNDED"] ',
        transactionNumber: "1234"
    }
}]



export const CREATE_USER = {
    title: "Create User",
    url: "https://backend.ezpayuae.com/integration/v1/user/",
    method: "POST",
    description: "This API for creating user.",
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "userName",
                type: [
                    "string"
                ],
                description: "The name of the user"
            },
            {
                inputParameter: "phone",
                type: [
                    "string"
                ],
                description: "The phone number of the user"
            },
            {
                inputParameter: "email",
                type: [
                    "string"
                ],
                description: "The email of the user"
            },
            {
                inputParameter: "referenceID",
                type: [
                    "string"
                ],
                description: "The reference ID of the user"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Request",
            value: {
                userName: "John Doe",
                phone: "+966581234567",
                email: "example@gmail.com",
                referenceID: "1234",
            }
        },
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    userName: "John Doe",
                    phone: "+966581234567",
                    email: "example@gmail.com",
                    referenceID: "1234",
                    createdAt: "2024-07-08T15:57:13.102Z"
                }
            }
        }
    ]
}

export const GET_USER = {
    title: "GET User",
    url: "https://backend.ezpayuae.com/integration/v1/user/{referenceID}",
    method: "GET",
    description: "This API for get user by referenceID.",
    sampleCodes: [
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    userName: "John Doe",
                    phone: "+966581234567",
                    email: "example@gmail.com",
                    referenceID: "1234",
                    createdAt: "2024-07-08T15:57:13.102Z"
                }
            }
        },
        {
            label: "Error Response",
            value: {
                status: 600,
                success: false,
                message: "User not found",
                body: {},
                error: "USER_NOT_FOUND"
            }
        }
    ]
}

export const UPDATE_USER = {
    title: "Update User",
    url: "https://backend.ezpayuae.com/integration/v1/user/{referenceID}",
    method: "PUT",
    description: "This API for update user by referenceID.",
    optionalFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "userName",
                type: [
                    "string"
                ],
                description: "The user name"
            },
            {
                inputParameter: "phone",
                type: [
                    "string"
                ],
                description: "The phone number of user"
            },
            {
                inputParameter: "email",
                type: [
                    "string"
                ],
                description: "The email of user"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Request",
            value: {
                userName: "John",
                phone: "+966581234111"
            }
        },
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {
                    userName: "John",
                    phone: "+966581234111",
                    email: "example@gmail.com",
                    referenceID: "1234",
                    createdAt: "2024-07-08T15:57:13.102Z"
                }
            }
        },
        {
            label: "Error Response",
            value: {
                status: 600,
                success: false,
                message: "User not found",
                body: {},
                error: "USER_NOT_FOUND"
            }
        }
    ]
}

export const DELETE_USER = {
    title: "Delete User",
    url: "https://backend.ezpayuae.com/integration/v1/user/{referenceID}",
    method: "DELETE",
    description: "This API for delete user by referenceID.",
    samoleCodes: [
        {
            label: "Response",
            value: {
                status: 200,
                success: true,
                message: "Operation Done Successfully",
                body: {}
            }
        },
        {
            label: "Error Response",
            value: {
                status: 600,
                success: false,
                message: "User not found",
                body: {},
                error: "USER_NOT_FOUND"
            }
        }
    ]
}

export const GET_TRANSACTION = {
    title: "Get Transaction",
    url: "https://backend.ezpayuae.com/integration/v1/transaction/{transactionNumber}",
    method: "GET",
    description: "This API for get transaction by transactionNumber.",
    sampleCodes: [
        {
            label: "Response",
            value: {
                referenceID: "ref198",
                amount: 100,
                invoiceURL: "https://www.ezpay.sa/payment?id=699c0c59278faa001569a339&country=KWT",
                currentStatus: "IN_PROGRESS",
                name: "ibrahim",
                phone: "+201011111111",
                serviceDetails: null,
                paymentDate: null,
                statusTrack: [
                    {
                        dateTime: "2024-07-25T09:09:22.000Z",
                        status: "IN_PROGRESS"
                    }
                ],
                type: "PAYMENT_LINK",
                createdAt: "2024-07-25T09:10:48.484Z"
            }
        },
        {
            label: "Error Response",
            value: {
                status: 619,
                success: false,
                message: "Transaction not found",
                body: {},
                error: "TRANSACTION_NOT_FOUND"
            }
        }
    ]
}

export const CREATE_TRANSACTION_API = {
    title: "Create Transaction",
    url: "https://backend.ezpayuae.com/integration/v1/transaction/",
    method: "POST",
    description: "This API for creating transaction.",
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "amount",
                type: [
                    "number"
                ],
                description: "The amount of the transaction"
            },
            {
                inputParameter: "referenceID",
                type: [
                    "string"
                ],
                description: "The reference ID of the user"
            },
            {
                inputParameter: "type",
                type: [
                    "string"
                ],
                description: "<b>NORMAL</b> the invoice is created for your user in the application.\n<b>PAYMENT_LINK</b> The invoice is created for your user in the application and a payment link is sent to the customer by SMS.\nIf you choose <b>PAYMENT_LINK</b> the phone number of the customer is required."
            }
        ]
    },
    optionalFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "name",
                type: [
                    "string"
                ],
                description: "The name of the customer"
            },
            {
                inputParameter: "phone",
                type: [
                    "string"
                ],
                description: "The phone number of the customer.\nThis field is required if the type is PAYMENT_LINK."
            },
            {
                inputParameter: "serviceDetails",
                type: [
                    "string"
                ],
                description: "The service details"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Response",
            value: {
                transactionNumber: 1234,
                referenceID: "78910",
                invoiceURL:"https://www.ezpay.sa/payment?id=699c0c59278faa001569a339&country=KWT",
                currentStatus: "IN_PROGRESS",
                amount: 100,
                name: "John Doe",
                phone: "+966581234567",
                serviceDetails: "This transaction is for buying a Blue Pen.",
                paymentDate: null,
                statusTrack: [
                    {
                        dateTime: "2024-07-08T14:40:09.465Z",
                        status: "IN_PROGRESS"
                    }
                ],
                type: "PAYMENT_LINK",
                createdAt: "2024-07-08T15:57:13.102Z"
            }
        },
        {
            label: "Error Response",
            value: {
                status: 619,
                success: false,
                message: "Transaction not found",
                body: {},
                error: "TRANSACTION_NOT_FOUND"
            }
        }
    ]
}

export const UPDATE_TRANSACTION_API = {
    title: "Update Transaction",
    url: "https://backend.ezpayuae.com/integration/v1/transaction/{transactionNumber}",
    method: "PUT",
    description: "This API for update transaction by transactionNumber.",
    optionalFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "amount",
                type: [
                    "number"
                ],
                description: "The amount of the transaction"
            },
            {
                inputParameter: "name",
                type: [
                    "string"
                ],
                description: "The name of the user"
            },
            {
                inputParameter: "phone",
                type: [
                    "string"
                ],
                description: "The phone number of the user"
            },
            {
                inputParameter: "serviceDetails",
                type: [
                    "string"
                ],
                description: "The service details"
            },
            {
                inputParameter: "referenceID",
                type: [
                    "string"
                ],
                description: "The reference ID of the user"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Request",
            value: {
                amount: 150,
                name: "Max Doe",
            }
        },
        {
            label: "Response",
            value: {
                transactionNumber: 1234,
                referenceID: "78910",
                invoiceURL: "https://www.ezpay.sa/payment?id=699c0c59278faa001569a339&country=KWT",
                currentStatus: "IN_PROGRESS",
                amount: 150,
                name: "Max Doe",
                phone: "+966581234567",
                serviceDetails: "This transaction is for buying a Red Pen.",
                paymentDate: null,
                statusTrack: [
                    {
                        dateTime: "2024-07-08T14:40:09.465Z",
                        status: "IN_PROGRESS"
                    }
                ],
                type: "PAYMENT_LINK",
                createdAt: "2024-07-08T15:57:13.102Z"
            }
        },
        {
            label: "Error Response",
            value: {
                status: 619,
                success: false,
                message: "Transaction not found",
                body: {},
                error: "TRANSACTION_NOT_FOUND"
            }
        }
    ]
}

export const CANCEL_TRANSACTION_API = {
    title: "Cancel Transaction",
    url: "https://backend.ezpayuae.com/integration/v1/transaction/cancel",
    method: "PUT",
    description: "This API for cancel transaction.",
    requiredFields: {
        columns: [
            { label: "Input Parameter", value: "inputParameter" },
            { label: "Type", value: "type" },
            { label: "Description", value: "description" }
        ],
        rows: [
            {
                inputParameter: "transactionNumber",
                type: [
                    "string"
                ],
                description: "The transaction number"
            }
        ]
    },
    sampleCodes: [
        {
            label: "Response",
            value: {
                transactionNumber: 1234,
                referenceID: "78910",
                currentStatus: "CANCELED",
                amount: 100,
                name: "John Doe",
                phone: "+966581234567",
                serviceDetails: "This transaction is for buying a Blue Pen.",
                paymentDate: null,
                statusTrack: [
                    {
                        dateTime: "2024-07-08T14:40:09.465Z",
                        status: "IN_PROGRESS"
                    },
                    {
                        dateTime: "2024-07-08T16:27:11.508Z",
                        status: "CANCELED"
                    }
                ],
                type: "PAYMENT_LINK",
                createdAt: "2024-07-08T15:57:13.102Z"
            }
        },
        {
            label: "Error Response",
            value: {
                status: 619,
                success: false,
                message: "Transaction not found",
                body: {},
                error: "TRANSACTION_NOT_FOUND"
            }
        }
    ]
}