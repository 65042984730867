import { Box, Chip, IconButton, Paper, Tooltip } from "@mui/material";
import { useCreateUserByAdminMutation, useGetUsersByAdminQuery } from "api/adminApi";
import { DataTable, MerchantName } from "modules/common";
import GroupsIcon from '@mui/icons-material/Groups';
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import UsersFilter from "./UsersFilter";
import UserDialog from "./UserDialog";
import SubUsersDialog from "./SubUsersDialog";
import { cloneDeep, get, isEmpty, omit, omitBy, pick, set } from "lodash";
import { Helmet } from "react-helmet";
import { $APP_NAME } from "constants";
import { useQueryParams } from "modules/hooks";
import UserStatusDialog from "./UserStatusDialog";
import { AccountBalance, Article } from "@mui/icons-material";
import { ACCOUNT_STATUS } from "constants";

const $COLUMN_WIDTH = 200
export default function AdminUsers() {
    const { setQueryParams, getQueryParams } = useQueryParams()

    const status_colors = { WAITING: "warning", ACCOUNT_CREATED: "primary", BLOCKED: "error", BLOCKED_BY_PARENT: "error", ACTIVE: "success" }
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [params, setParams] = useState({
        page: 1,
        limit: 100,
    })

    const queryParams = getQueryParams()

    useEffect(() => {
        if (!isEmpty(queryParams)) {
            setParams(queryParams)
        }else{
            setQueryParams(params)
        }
    }, [queryParams])

    const {
        data: users,
        isLoading: usersLoading,
        isFetching,
        refetch
    } = useGetUsersByAdminQuery(queryParams)

    //======= User Status =======
    const [openUserStatusDialog, setOpenUserStatusDialog] = useState(false)
    const [user, setUser] = useState(null)

    const openUserStatusDialogHandler = (user) => {
        setUser(user)
        setOpenUserStatusDialog(true)
    }

    const closeUserStatusDialogHandler = () => {
        setUser(null)
        setOpenUserStatusDialog(false)
    }

    const UserStatus = ({ value, row }) => {
        return (
            <Box display="flex" alignItems="center">
                <Chip
                    label={t(`UserStatus.${value}`)}
                    sx={{ color: "white", flex: 1, fontWeight: 500 }}
                    color={status_colors[value]}
                    onClick={(e) => {
                        e.stopPropagation();
                        openUserStatusDialogHandler({ id: row?.id, currentStatus: row?.currentStatus })
                    }}
                />
                {
                    (row?.currentStatus === ACCOUNT_STATUS.accountCreated && !row?.parent) &&
                    <Box ml={15} mt={5}>
                        {row?.bankData &&
                            <Tooltip title={t("The Bank account completed.")} placement="top">
                                <AccountBalance sx={{ mx: 2, color: "#6C6C6C", }} fontSize="small" />
                            </Tooltip>
                        }
                        {row?.document &&
                            <Tooltip title={t("The documents uploaded.")} placement="top">
                                <Article sx={{ mx: 2, color: "#6C6C6C", }} fontSize="small" />
                            </Tooltip>
                        }
                    </Box>
                }
            </Box>
        )
    }

    const SubContacts = ({ id }) => (
        <IconButton onClick={(e) => { e.stopPropagation(); openSubContactsDialogHandler(id) }} >
            <GroupsIcon fontSize="100" />
        </IconButton>
    )

    const renderUserCell = ({ row }) => (
        <MerchantName
            creator={pick(row, ['userName', 'parent', 'id'])}
            AccessoryView={
                !row?.parent
                    ?
                    <Tooltip title={t("View Sub Accounts")}>
                        <Box>
                            <SubContacts id={get(row, 'id')} />
                        </Box>
                    </Tooltip>
                    : null
            }
        />
    )

    let columns = [
        { field: "userName", renderCell: renderUserCell, sortable: false, headerName: t("Name"), width: $COLUMN_WIDTH * 1.1 },
        { field: "business", valueGetter: ({ value }) => value?.businessName, sortable: false, headerName: t("Business Name"), width: $COLUMN_WIDTH * 1.1 },
        { field: "email", sortable: false, headerName: t("Email"), width: $COLUMN_WIDTH * 1.2, },
        { field: "phone", sortable: false, headerName: t("Phone"), width: $COLUMN_WIDTH * 1 },
        { field: "currentStatus", renderCell: UserStatus, sortable: false, headerName: t("Status"), width: $COLUMN_WIDTH * 1.1 },
        { field: "createdFrom", valueGetter: ({ value }) => t(value ?? ''), sortable: false, headerName: t("Account Source"), width: $COLUMN_WIDTH * 0.8 },
        { field: "createdAt", valueGetter: ({ value }) => moment(value).format("DD/MM/YY - h:mm A"), sortable: false, headerName: t("Created At"), width: $COLUMN_WIDTH * 0.8 },
    ];

    //======================Create user handlers==================
    const [createUserByAdmin, { isLoading: createUserLoading, isSuccess: createUserIsSuccess, error: createUserError, reset }] = useCreateUserByAdminMutation()
    const [userDialogOpen, setUserDialogOpen] = useState(false)

    const closeUserDialogHandler = () => {
        setUserDialogOpen(false)
        reset()
    }
    const userDialogOpenHandler = () => {
        reset()
        setUserDialogOpen(true)
    }
    const createUserByAdminHandler = async (payload) => {
        let response = await createUserByAdmin(payload).unwrap()
        if (response?.success) {
            refetch()
            setTimeout(() => {
                closeUserDialogHandler()
            }, 2500);
        }
    }

    //======Get sub users dialog========
    const [openSubContacts, setOpenSubContacts] = useState(false)
    const [parentId, setParentId] = useState(null)

    const openSubContactsDialogHandler = (id) => {
        setParentId(id)
        setOpenSubContacts(true)
    }

    //======= Handle change params =======
    const [searchParams] = useSearchParams()
    const status = searchParams.get("status")


    useEffect(() => {
        if (status) {
            setParams((prevParams) => ({ ...prevParams, currentStatus: status }))
        }
    }, [status])

    const pageChangeHandler = ({ page }) => {
        if (!isFetching) {
            let cloneParams = cloneDeep(params)
            setQueryParams({ ...cloneParams, page })
        }
    }

    const paramChangeHandler = ({ target: { name, value } }) => {
        let cloneParams = cloneDeep(params)
        if (name === "type") {
            cloneParams = omit(cloneParams, ['parent', 'childs'])
            if (!!value) {
                cloneParams = set(cloneParams, value, true)
            }
            setQueryParams(cloneParams)
            return
        }

        setQueryParams({ ...cloneParams, [name]: value })
    }

    return (
        <>
            <Helmet>
                <title>{t("Merchants List")} | {$APP_NAME}</title>
            </Helmet>
            <Box mr={5}>
                <UserStatusDialog open={openUserStatusDialog} onClose={closeUserStatusDialogHandler} user={user} />
                <UsersFilter params={params} onParamsChange={paramChangeHandler} onUserDialogOpen={userDialogOpenHandler} />
                <UserDialog
                    open={userDialogOpen}
                    onClose={closeUserDialogHandler}
                    loading={createUserLoading}
                    isSuccess={createUserIsSuccess}
                    error={createUserError}
                    onCreate={createUserByAdminHandler}
                />
                <SubUsersDialog
                    open={openSubContacts}
                    onClose={() => {
                        setOpenSubContacts(false)
                        setParentId(null)
                    }}
                    parentId={parentId}
                />
                <Paper sx={{ height: "80vh" }}>
                    <DataTable
                        rows={users?.docs ?? []}
                        columns={columns}
                        onPaginationModelChange={pageChangeHandler}
                        paginationModel={{ page: params?.page - 1, pageSize: 100 }}
                        rowCount={users?.totalDocs ?? 0}
                        loading={usersLoading || isFetching}
                        onRowClick={row => navigate(`/admin/user/${row?.id}`)}
                    />
                </Paper>
            </Box>
        </>
    )
}