import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import moment from "moment";
import { SOURCE_TYPE } from "constants";

export default function UsersFilter({ params, onParamsChange, onUserDialogOpen }) {
    
    const theme = useTheme()
    const { t } = useTranslation()
    
    //======= Search key handler ============
    const [searchKey, setSearchKey] = useState('');
    const timerRef = useRef(null)
    const onSearchKey = ({ target: { value } }) => {
        setSearchKey(value)
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            onParamsChange({ target: { name: "searchKey", value } })
        }, 1000);
    }

    useEffect(() => {
        if(!!params?.searchKey && params?.searchKey !== searchKey){
            setSearchKey(params?.searchKey)
        }
    }, [params?.searchKey])

    return (
        <Grid container justifyContent="space-between" alignItems="center" spacing={10} sx={{ my: 10 }}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t("Users")}</Typography>
                    <Tooltip title={t("Add new user")}>
                        <IconButton onClick={onUserDialogOpen} color="primary" size="small" sx={{ ml: 20, borderWidth: "1px", borderColor: "primary", borderStyle: "solid" }}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
                <TextField
                    fullWidth
                    label={t("Search")}
                    size="small"
                    value={searchKey}
                    onChange={onSearchKey}
                    InputProps={
                        theme.direction === "rtl" ?
                            {
                                endAdornment: <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>,
                            }
                            :
                            {
                                startAdornment: <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>,
                            }
                    }
                />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        error={true}
                        maxDate={moment(params?.toDate ?? '')}
                        label={t("From")}
                        value={params?.fromDate ? moment(params?.fromDate) : null}
                        onChange={(newValue) => {
                            let offset = Number(new Date().getTimezoneOffset() * -1)
                            onParamsChange({ target: { name: "fromDate", value: newValue ? newValue.utcOffset(offset).startOf("day").toISOString() : null } })
                        }}
                        slotProps={{
                            textField: (textFieldParams) => {
                                return {
                                    fullWidth: true,
                                    size: "small",
                                    InputProps: {
                                        ...textFieldParams?.InputProps,
                                        startAdornment: !!params?.fromDate ?
                                            <InputAdornment position="start">
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation()
                                                    onParamsChange({ target: { name: "fromDate", value: null } })
                                                }} size='small' color='primary'>
                                                    <Clear sx={{ fontSize: "inherit" }} />
                                                </IconButton>
                                            </InputAdornment> : ''
                                    },

                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        minDate={moment(params?.fromDate ?? '')}
                        label={t("To")}
                        value={params?.toDate ? moment(params?.toDate) : null}
                        onChange={(newValue) => {
                            let offset = Number(new Date().getTimezoneOffset() * -1)
                            onParamsChange({ target: { name: "toDate", value: newValue ? newValue.utcOffset(offset).startOf("day").toISOString() : null } })
                        }}
                        slotProps={{
                            textField: (textFieldParams) => {
                                return {
                                    fullWidth: true,
                                    size: "small",
                                    InputProps: {
                                        ...textFieldParams?.InputProps,
                                        startAdornment: !!params?.toDate ?
                                            <InputAdornment position="start">
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation()
                                                    onParamsChange({ target: { name: "toDate", value: null } })
                                                }} size='small' color='primary'>
                                                    <Clear sx={{ fontSize: "inherit" }} />
                                                </IconButton>
                                            </InputAdornment> : ''
                                    },
                                }
                            }
                        }}
                    />
                </Grid>
            </LocalizationProvider>
            <Grid item xl={2} lg={2} sm={4} xs={12}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="account-type-label">{t("Account type")}</InputLabel>
                    <Select
                        labelId="account-type-label"
                        id="account-type"
                        name="type"
                        value={params?.parent ? "parent" : params?.childs ? "childs" : null}
                        label={t("Account type")}
                        onChange={onParamsChange}
                        startAdornment={
                            (params?.parent || params?.child) ?
                                (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                onParamsChange({ target: { name: "type", value: null } });
                                                
                                            }}
                                            size='small'
                                            color='primary'
                                        >
                                            <Clear sx={{ fontSize: "inherit" }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                                : null
                        }
                    >
                        <MenuItem value="parent">{t("Main accounts")}</MenuItem>
                        <MenuItem value="childs">{t("Sub accounts")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xl={2} lg={2} sm={4} xs={12}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="account-status-label">{t("Account status")}</InputLabel>
                    <Select
                        labelId="account-status-label"
                        id="account-status"
                        name="currentStatus"
                        value={params?.currentStatus ?? null}
                        label={t("Account status")}
                        onChange={onParamsChange}
                        startAdornment={
                            !!params?.currentStatus ?
                                (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                onParamsChange({ target: { name: "currentStatus", value: null } });
                                            }}
                                            size='small'
                                            color='primary'
                                        >
                                            <Clear sx={{ fontSize: "inherit" }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                                : null
                        }
                    >
                        <MenuItem value={"ACCOUNT_CREATED"}>{t("UserStatus.ACCOUNT_CREATED")}</MenuItem>
                        <MenuItem value={"WAITING"}>{t("UserStatus.WAITING")}</MenuItem>
                        <MenuItem value={"ACTIVE"}>{t("UserStatus.ACTIVE")}</MenuItem>
                        <MenuItem value={"BLOCKED"}>{t("UserStatus.BLOCKED")}</MenuItem>
                        <MenuItem value={"BLOCKED_BY_PARENT"}>{t("UserStatus.BLOCKED_BY_PARENT")}</MenuItem>
                        <MenuItem value={"REJECTED_BY_SERVICE_PROVIDER"}>{t("UserStatus.REJECTED_BY_SERVICE_PROVIDER")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xl={2} lg={2} sm={4} xs={12}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="createdFrom-label">{t("Account Source")}</InputLabel>
                    <Select
                        labelId="createdFrom-label"
                        id="createdFrom"
                        name="createdFrom"
                        value={params?.createdFrom ?? null}
                        label={t("Transaction Source")}
                        onChange={onParamsChange}
                        startAdornment={
                            !!params?.createdFrom ?
                                (<InputAdornment position="start">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onParamsChange({ target: { name: "createdFrom", value: null } });
                                        }}
                                        size='small'
                                        color='primary'
                                    >
                                        <Clear sx={{ fontSize: "inherit" }} />
                                    </IconButton>
                                </InputAdornment>)
                                : null
                        }
                    >
                        {Object.values(SOURCE_TYPE).map((source) => (
                            <MenuItem value={source} key={source}>{t(source)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid >
    )
}